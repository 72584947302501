.table-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #80808021;
  top: 0px;
  left: 0;
  z-index: 1000;
  margin: auto;
  min-height: 600px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.application-table {
  display: grid;
  grid-template-rows: 1fr auto;
  position: relative;
  min-height: 600px;
}
