@import "../../styles/variables.scss";
.bg-light {
    background-color: $white !important;
}

.user-avatar {
  display: flex;
  align-items: center !important;
  background-color: $gray-8;
}

.ui {
  display: flex;
  align-items: stretch;
}

.navbar-brand {
    margin: -10px 0px;
}

#avatar-toggle::after {
    display: none;
}
