.page-link {
  &[disabled] {
    &:hover {
      background-color: unset;
      color: gray;
    }
    cursor: not-allowed;
    color: gray !important;
    background-color: #eaeaea57 !important;
  }
}
