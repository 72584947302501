@import "../../styles/variables.scss";
.file-container {
    background: $white;
    border: 1px solid lightgray;
    border-radius: $radius;
    align-items: center;
    padding: .5rem;
    font-size: .8em;
    cursor: pointer;
    ion-icon {
        font-size: 2em;
    }
    ion-icon {
        color: grey;
    }
    img {
        width: 35px;
        height: 35px;
        margin-left: .5rem;
    }
}

.file-container:hover {
  border-color: $primary-highlight;
}