@import "../../styles/variables.scss";

.avatar-container {
  border-radius: 100%;
  background: $primary-color;
  transition: $transition;
}

.avatar-container:hover {
  background-color: $primary-highlight
}

.avatar-image,
.initials {
  height: 52px;
  width: 52px;
  user-select: none;
}

.initials {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  span {
    font-size: 12pt;
    letter-spacing: 0;
  }
}
