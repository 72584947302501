@import "../../styles/variables.scss";



.application-table-control-container {
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid grey;
}

.application-table {
  background: $white;
  min-height: 60vh;
  border-radius: $radius;
  align-items: flex-start;

  .table thead th {
    vertical-align: middle !important;
  }


  tbody tr {
    td {
      vertical-align: middle;
    }
    margin-bottom: 5px;
    .cta-arrow {
      opacity: 0;
      color: $primary-color;
    }

    .new-cta {
      // left: 70px;
      // margin-top: -1px;
      color: $primary-color;
      span {
        position: relative;
      }
    }

    &:hover {
      cursor: pointer;
      // border: 1px solid $primary-color;
      background-color: #42d3c030 !important;
      // border-radius: $radius;

      .cta-arrow {
        opacity: 1;
      }
    }
  }
}

#application-detail {
  height: 100%;
}

.detail-sidebar {
  background-color: $dark-blue;
  color: $white;
  
  .meta-logo {
    font-size: 12px;
    color: #b3b4b4;
  }
  .meta {
    font-size: 12px;
    color: #b3b4b4;
  }
}
.sidebar-section {
  display: flex;
  flex-direction: column;
  position: relative;
}

.sidebar-status {
  width: 108%;
  position: absolute;
  border-radius: 0px 20px 20px 0;
}

.sidebar-sub-section {
  margin-bottom: 12px;
}

.sidebar-sub-section:not(:first-of-type) {
  border-top: 1px solid $primary-highlight;
}

.sidebar-logo-container {
  display: flex;
  background-color: white;
  padding: .5em;
  margin-bottom: 1rem;
}

.activity-circle-icon {
  // width: 35px;
  // height: 35px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // background: white;
  // border-radius: 100%;
  color: $primary-color;
  font-size: 1.5em;
  // box-shadow: 0px -1px 1px grey;
  // * {
  //     position: relative;
  //     top: 30px;
  // }
}

.activity-feed-item {
  background: $white;
  padding: 1.4rem;
  border-radius: $radius;
}

.application-navigation {
  padding-top:4px;
  padding-left:17px;
  display: flex;
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  margin-bottom: 1rem;
  background: $white;
  a {
    text-decoration: none;
  }
}

.documents-container {
  background: white;
  height: calc(100% - (65px));
  border-radius: $radius;
  border: solid 1px $primary-color;
}

.content-container {
  background: white;
  height: calc(100% - (65px));
  border-radius: $radius;
}

@media screen and (max-width: 767px) {
  .sidebar-status {
    width: 100%;
}

.sidebar-status {
  width: 100%;
}
}
