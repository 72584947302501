@import "../../styles/variables.scss";

.nav-header {
  display: flex;
  border-radius: 10px 0 0 10px;
  box-shadow: $shadow-left;
  background-color: $gray-8;
  overflow: hidden;
  a {
    text-decoration: none;
  }
}

.nav-link {
  margin-right: 1px;
  background-color: $gray-8;
  border-radius: 10px 10px 0 0;
  transition: $transition;
  span{
    color: $white;
    text-decoration: none;
  }
}

.nav-header-tab {
  min-width: 120px;
  transition: $transition;
  padding: 14px 10px;
  text-align: center;
  font-weight: bold;
  height: 100%;
  display:flex;
  align-items: center;
  span{
    color: $white;
    text-decoration: none;
  }
}


.nav-link.active {
  background-color: $primary-color;
}

.nav-link:hover, .nav-header-tab:hover {
  background-color: $primary-highlight;
}