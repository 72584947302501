@import "../../styles/variables.scss";

.messageRecord__container {

  &_user {
    border-radius: $radius;
    padding: 24px;
    border: solid 1px $primary-highlight;
    color: $primary-highlight;
  }

  &_admin {
    border-radius: $radius;
    padding: 24px;
    border: solid 1px $tertiary-color;
    color: $tertiary-color;
  }
}

.messageRecord__title {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.messageRecord__content {
  &_user {
    font-size: 14px;
    color: $gray-7;
    line-height: 18px;
    padding-left: 24px;
    border-left: solid 1px $primary-highlight;
  }

  &_admin {
    font-size: 14px;
    color: $gray-7;
    line-height: 18px;
    padding-left: 24px;
    border-left: solid 1px $tertiary-color;
  }
}

.messageRecord__expandMessage {
  text-decoration: underline !important;
}
.messageRecord__expandMessage:hover {
  cursor: pointer;
}